import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Button } from "antd-mobile";
import { GetAllCategoryApi } from "@/api/category";
import { GetAllArticleApi } from "@/api/article";
import "./index.less";
import Header from "@/component/Header";
import CategoryMenu from "./component/CategoryMenu";
import ArticleCard from "./component/ArticleCard";
import { Tabs, Result } from "antd-mobile";

const fixedCategory = {
  id: 0,
  name: "最新",
};

function Home() {
  const urlParams = useParams();
  const navigate = useNavigate();

  const [category, setCategory] = useState([]);
  const [activeCategoryId, setActiveCategoryId] = useState(null);

  const [article, setArticle] = useState([]);

  // 获取类别
  const getAllCategory = async () => {
    const res = await GetAllCategoryApi();
    const { code, data } = res;
    if (code !== 200) return;
    setCategory([fixedCategory, ...data]);
  };

  // 初始化设置 activeCategoryId
  const initSetActiveCategoryId = () => {
    let { category_id } = urlParams;
    if (category_id === undefined) {
      category_id = 0;
    }

    Promise.resolve()
      .then(() => {
        setActiveCategoryId(category_id);
      })
      .then(() => {
        setActiveCategoryId((preState) => {
          getArticle(preState);
          return preState;
        });
      });
  };

  // 获取文章
  const getArticle = async (id) => {
    const res = await GetAllArticleApi({
      filter: {
        category_id: id * 1,
      },
    });
    console.log(res);
    const { code, data } = res;
    if (code !== 200) return;
    setArticle(data.list);
  };

  // 切换类别，获取文章
  const onChangeActiveCategoryId = async (e) => {
    await getArticle(e);
    setActiveCategoryId(e);
    const url = `/${e * 1 === 0 ? "" : e}`;
    navigate(url);
  };

  // 跳转文章
  const gotoArticle = (id)=>{
    navigate(`/article/${id}`)
  }

  useEffect(() => {
    (async () => {
      await getAllCategory();
      initSetActiveCategoryId();
    })();
  }, []);

  return (
    <div className="home-container">
      {/* 顶部*/}
      <div className="inner-header-container">
        <Header category={category} />
      </div>
      <div className="content-container">
        <div className="content-category-container">
          <CategoryMenu
            category={category}
            activeKey={activeCategoryId}
            onChange={onChangeActiveCategoryId}
          />
        </div>
        <div className="horizontal-category">
          <Tabs
            activeKey={activeCategoryId * 1}
            style={{ width: "100%" }}
            onChange={onChangeActiveCategoryId}
          >
            {category.map((item) => {
              return <Tabs.Tab title={item.name} key={item.id * 1} />;
            })}
          </Tabs>
        </div>
        <div className="article-list-container">
          {article.length > 0 ? (
            article.map((item) => {
              return (
                <div key={item.id} className="article-item-container"
                onClick={()=>{gotoArticle(item.id)}}
                >
                  <ArticleCard {...item} />
                </div>
              );
            })
          ) : (
            <div>
              <Result
                icon={<></>}
                status="success"
                title="no more article"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;

import React from "react";
import "./index.less";
import moment from "moment";
import eyeSvg from "./eye.svg";
import { prod_image_url, dev_image_url } from "@/constant";
import { Tag } from "antd-mobile";

const image_prefix =
  process.env.NODE_ENV === "development" ? dev_image_url : prod_image_url;

const App = ({ image_url, title, sub_title, createdAt, label }) => {
  console.log(label);
  const imageSrc = (url) => {
    return `${image_prefix}/${url}`;
  };

  const cTime = (t) => {
    return moment(t).format("LL");
  };

  return (
    <div className="article-card-container">
      <div className="article-card-left">
        <img src={imageSrc(image_url)} />
      </div>
      <div className="article-card-right">
        <div className="title-text-container">
          <div className="title">{title}</div>
          <div className="subTitle">{sub_title}</div>
        </div>
        <div className="bottom-line">
          <div className="label-container">
            {label ? (
              <>
                {label.split(",").map((item, index) => {
                  return (
                    <Tag
                      style={{ marginRight: 8 }}
                      color="#108ee9"
                      key={index}
                    >
                      {`#${item}`}
                    </Tag>
                  );
                })}
              </>
            ) : (
              <></>
            )}
          </div>
          {/* <div className="common-center-flex svg-box">
            <img src={eyeSvg} />
          </div> */}
          <div className="common-center-flex">{cTime(createdAt)}</div>
        </div>
      </div>
    </div>
  );
};

export default App;

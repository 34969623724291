import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { GetOneArticleApi } from '@/api/article'
import Header from "@/component/Header";
import './index.less'
import { prod_image_url, dev_image_url } from '@/constant'
import moment from "moment";
import { Tag } from "antd-mobile";

import handleHtml from '@/utils/handleHtml.js'
const image_prefix =
    process.env.NODE_ENV === "development" ? dev_image_url : prod_image_url;


export default () => {
    const urlParams = useParams();

    const _ref = useRef(null)

    const [state, setState] = useState({})

    const [navigatorState, setNavigatorState] = useState([])

    const cTime = (t) => {
        return moment(t).format('LL')
    }
    const getArticle = async (id) => {
        const res = await GetOneArticleApi({ id })
        document.title = res.data.title
        setState(res.data)
    }

    const imageSrc = (url) => {
        return `${image_prefix}/${url}`;
    };

    const setNavigator = (html) => {
        const res = handleHtml(html)
        setNavigatorState(res)
    }

    const getNavigatorItemClass = (tagName) => {
        const common = `navigator-item-common`
        const h1 = `navigator-item-h1`
        const h2 = `navigator-item-h2`
        if (tagName === 'H1') return `${common} ${h1}`
        if (tagName === 'H2') return `${common} ${h2}`
    }

    const slideToNavigatorTitle = (tagId) => {
        console.log(tagId);
        const ele = document.getElementById(tagId)
        window.scrollTo(0, ele.offsetTop || 0)
    }

    useEffect(() => {
        (async () => {
            const { article_id } = urlParams
            await getArticle(article_id)
        })()
    }, [])

    useEffect(() => {
        if (state.type === 1) {
            if (!_ref.current) return
            setNavigator(_ref.current)
        }
    }, [state])

    return (
        <div className='article-container'>
            {
                navigatorState.length > 0 && (
                    <div className='navigator-container'>
                        <div className='navigator-title'>
                            目录
                        </div>
                        {
                            navigatorState.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={getNavigatorItemClass(item.tagName)}
                                        onClick={() => { slideToNavigatorTitle(item.id) }}
                                    >{item.innerHtml}</div>
                                )
                            })
                        }
                    </div>
                )
            }
            <div className='article-header-container'>
                <Header />
            </div>

            <div className='article-content-container'>
                <div className='article-image-container'>
                    <img src={imageSrc(state.image_url)} alt="" />
                </div>

                <div className='title-container common-center-flex'>{state.title}</div>
                <div className='subTitle-container'>{state.sub_title}</div>
                <div className='time-container'>
                    <div>
                        {state.label && state.label.split(",").map((item, index) => {
                            return (
                                <Tag
                                    style={{ marginRight: 8 }}
                                    color="#108ee9"
                                    key={index}
                                >
                                    {`#${item}`}
                                </Tag>
                            );
                        })}
                    </div>
                    <div>
                        {cTime(state.createdAt)}</div>
                </div>
                {
                    state.show_webview && (
                        <div className='webview-container' style={{ height: state.webview_height }}>
                            <iframe src={state.webview_url} />
                        </div>
                    )
                }

                <div className='text-container'>
                    {
                        state.type === 1 && (
                            <div ref={_ref} dangerouslySetInnerHTML={{ __html: state.content }} />
                        )
                    }

                    {
                        state.type === 2 && (
                            <div>222</div>
                        )
                    }
                </div>

            </div>
        </div>
    )
}
export default {
    userInfo: {
        set: Symbol()
    },
    globalLoadState: {
        set: Symbol()
    },
    guaSearchFilter: {
        set: Symbol()
    }
}
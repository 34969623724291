import actions from '../actions'
export default (filterState = {
    order: 1,
    query: {}
}, action) => {
    switch (action.type) {
        case actions.guaSearchFilter.set:
            filterState = { ...filterState, ...action.data }
            break
    }
    return filterState
}
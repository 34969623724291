import React from "react";
import { Button, InputItem } from "antd-mobile";
import "./index.less";

const App = ({ category, activeKey, onChange }) => {
  return (
    <div className="category-container">
      {category.length > 0 &&
        category.map((item) => {
          return (
            <div
              key={item.id}
              className={`common ${
                activeKey * 1 === item.id * 1 ? "active-item" : ""
              }`}
              onClick={() => {
                onChange(item.id);
              }}
            >
              <div>{item.name}</div>
              {item.hasOwnProperty("articleRows") && (
                <div className="num">{item?.articleRows?.length}</div>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default App;

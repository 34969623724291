import { Navigate, useRoutes } from "react-router-dom";
import Home from "@/page/Home";
import Article from "@/page/Article";

const rootRouter = [
  {
    path: "/:category_id?",
    exact: true,
    element: <Home />,
  },
  {
    path: "/Article/:article_id",
    exact: true,
    element: <Article />,
  },
];

const RootRouter = () => {
  const routes = useRoutes(rootRouter);
  return routes;
};

export default RootRouter;

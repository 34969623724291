import React, { useEffect } from 'react'
import { Route, BrowserRouter, Routes, Navigate, useLocation } from "react-router-dom";

import RootRouter from "@/routes";

import { Provider } from "react-redux";
import store from '@/store'
export default () => {

  return (
    <Provider store={store}>
      <BrowserRouter>
        <RootRouter />
      </BrowserRouter>
    </Provider>
  )
}
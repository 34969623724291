const handleTag = (tagName) => {
  if (tagName === "H1") {
    console.log(tagName);
  }
};

export default (html) => {
  const res = [];

  const id_prefix = `article-navigation-ele-`;

  for (let i = 0; i < html.children.length; i++) {
    const child = html.children[i];
    const tagName = child.tagName;
    const innerHtml = child.innerHTML;
    const id = `${id_prefix}${i}`;
    const appendFlag = tagName === "H1" || tagName === "H2";
    if (appendFlag) {
      child.setAttribute("id", id);
      res.push({
        tagName,
        innerHtml,
        id,
      });
    }
  }
  return res
};

import React from "react";
import { Button } from "antd-mobile";
import "./index.less";
import Ava from "./header-avatar.png";

const App = ({category}) => {
  return (
    <div className="header-container">
      <div className="left-logo">Hello</div>
      <div className="header-right">
        <img src={Ava} />
        <span>Zb</span>
      </div>
    </div>
  );
};

export default App;
